[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: none;
  --amplify-components-authenticator-router-border-width: 0px;
  --amplify-components-button-primary-hover-background-color: var(
    --chakra-colors-primary-600
  );
  --amplify-components-button-primary-background-color: var(
    --chakra-colors-primary-200
  );
  --amplify-components-button-primary-color: var(--chakra-colors-black);
  --amplify-components-button-font-weight: 600;

  --amplify-components-button-link-hover-color: var(
    --chakra-colors-primary-900
  );
  --amplify-components-button-link-color: var(--chakra-colors-primary-600);
  --amplify-components-button-link-hover-background-color: var(
    --chakra-colors-primary-50
  );
  --amplify-components-input-border-color: var(--chakra-colors-gray-200);
  --amplify-components-button-border-color: var(--chakra-colors-gray-200);
}
