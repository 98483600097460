.simple-input {
  border: 1px solid black;
  border-radius: 0;
  width: "100%";
}

.clickable-table tr:hover {
  cursor: pointer;
}

.pac-container {
  z-index: 1500; /* needed because modals have z-index: 1400 */
}

.hide {
  display: none;
}
.sidebarItem.active,
.sidebarItem:hover {
  color: #e51873;
}

.rideProviderBox {
  border-color: #12a19a;
  margin: 5.6rem 2rem;
}
.itemBorderColor {
  border-color: #12a19a;
  margin-bottom: 1rem;
}

.deploymentRuleTable {
  padding: 1rem;
}
.limitInput {
  width: 85%;
  margin: 0 auto;
}
.programLinks {
  width: fit-content;
}

.homeContainer {
  margin: 5rem auto;
  width: 50%;
}
.homeLogo {
  margin: 4rem auto;
}
.newSponsorBtn {
  margin-left: 8px;
}

div.selRideProvider > .textSelected_decoration {
  padding: 0.1rem 0.5rem;
  margin: 0.5rem 0.2rem;
  background-color: rgb(109 197 193 / 30%);
  border-radius: 0.6rem;
  line-height: 1.8rem;
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.text_X {
  margin-left: 0.2rem;
  font-size: 1rem;
  font-weight: 500;
}
.relative {
  position: relative;
}
.arrow_RP_dropdown {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  z-index: 1;
}
.arrow_RP_dropdown > .arrowBtnPosition {
  height: 100%;
  padding: 0 0.7rem;
}

.ul-RPlist {
  background-color: white;
  border: solid 0.1px black;
  max-height: 20vh;
  overflow: auto;
}

.listDropDown {
  list-style: none;
  font-size: 16px;
  padding: 6px;
  color: black;
  top: 50%;
  flex-direction: column;
  display: flex;
  align-items: left;
  text-transform: uppercase;
}
.listDropDown:hover {
  background-color: #cbd5e0;
  opacity: 3;
  color: white;
}
.selectBox {
  display: grid;
  height: 2.5rem;
  border-bottom: 1px solid;
  border-color: "black";
}
.selectBox.comboSelect {
  margin-left: 0.3rem;
}
.containerInputCombo {
  margin-bottom: 10px;
}
